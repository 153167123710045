<template>
  <div v-if="items && items.length > 0">
    <!-- Search input -->
    <div class="d-flex align-items-center mb-3">
      <div class="h3 m-0" style="flex: 3;">Total: {{ totalRows }}</div>
    </div>
    <!-- Sort -->
    <div class="d-flex align-items mb-2">
      <div style="flex: 3;">
        <b-link to="add">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
          >
            <feather-icon icon="PlusCircleIcon" class="mr-50" />
            <span class="align-middle">Add new</span>
          </b-button>
        </b-link>
      </div>
      <!-- Sort -->
      <b-form-group
        label="Sorted by"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0 d-flex align-items-center justify-content-center"
        style="flex: 1;"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </div>
    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      borderless
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(fullname)="item">
        <b-link :to="`detail/${item.item.id}`">
          {{ item.item.fullname }}
        </b-link>
      </template>
      <!-- <template #cell(is_active)="item">
        <ActiveStatus :value="item.item.is_active" />
      </template> -->
      <template #cell(more)="item">
        <div class="d-flex align-items-center">
          <b-link
            :to="{ path: `detail/${item.item.id}` }"
            class="text-secondary"
          >
            <feather-icon icon="EyeIcon" class="mr-50" />
          </b-link>
          <b-link
            :to="{ path: `edit/${item.item.id}` }"
            class="text-secondary mx-1"
          >
            <feather-icon icon="Edit3Icon" class="mr-50" />
          </b-link>
          <div class="text-danger" @click="handleDelete(item.item.id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
          </div>
        </div>
      </template>
      <template #cell(sort)="item">
        <div class="d-flex align-items-center">
          <feather-icon
            :class="{ disabled: items[item.index - 1] === undefined }"
            icon="ArrowUpIcon"
            class="text-success mr-1"
            size="2x"
            @click="handleSort(item, 'up')"
          />
          <feather-icon
            :class="{ disabled: items[item.index + 1] === undefined }"
            icon="ArrowDownIcon"
            class="text-danger"
            size="2x"
            @click="handleSort(item, 'down')"
          />
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="10" sm="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <no-data />
  </div>
</template>
<script>
import {
  BButton,
  BLink,
  BPagination,
  BFormGroup,
  BFormSelect,
  BTable,
  BRow,
  BCol,
  BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import NoData from '@/views/components/nodata/index.vue'
// import ActiveStatus from '@/views/components/active-status/index.vue'

export default {
  components: {
    BButton,
    BLink,
    BPagination,
    BFormGroup,
    BFormSelect,
    BTable,
    BRow,
    BCol,
    BInputGroup,
    NoData,
    // ActiveStatus,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      fields: [
        { key: 'fullname', label: 'Full name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'phone', label: 'phone', sortable: true },
        { key: 'position', label: 'Position', sortable: true },
        { key: 'created_at', label: 'Created at', sortable: true },
        // { key: 'is_active', label: 'Is active', sortable: false },
        { key: 'more', label: 'More', sortable: false },
        { key: 'sort', label: 'Sort', sortable: false },
      ],
      items: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.loadList()
  },
  methods: {
    async handleSort(item, method) {
      try {
        const params = {
          template_id: JSON.parse(localStorage.getItem('templateID')).id,
          type: 1,
          ids: [],
        }
        if (method === 'up') {
          params.ids = [item.item.id, this.items[item.index - 1].id]
        }
        if (method === 'down') {
          params.ids = [item.item.id, this.items[item.index + 1].id]
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_contact/change_position`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async loadList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_contacts?template_id=${templateId}&limit=1000`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.perPage = res.count_page
          this.currentPage = res.current_page
          this.totalRows = res.items.length
          this.items = res.items
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const templateId = JSON.parse(localStorage.getItem('templateID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_contact/${id}?template_id=${templateId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Contact has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
  },
}
</script>
